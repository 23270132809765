import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./ThenAndNow.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Tile from "./../../../../res/thennow/tile.png";
import InSitu1 from "./../../../../res/thennow/insitu-1.jpg";
import InSitu2 from "./../../../../res/thennow/insitu-2.jpg";
import InSitu3 from "./../../../../res/thennow/insitu-3.jpg";
import InSitu4 from "./../../../../res/thennow/insitu-4.jpg";
import InSitu5 from "./../../../../res/thennow/insitu-5.jpeg";

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: InSitu2, name: "InSitu2" },
    { src: InSitu3, name: "InSitu3" },
    { src: InSitu4, name: "InSitu4" },
  ],
};

const ThenAndNow = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="ThenNow"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>THEN & NOW<h2 style={{paddingLeft: "16px"}}></h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="ThenNow"/>
        <br />
        <p>
           Ainsi sont convoqués les fantômes qui peuplent l’ADN originel de GHOST GALERIE, les spectres de sa passion pour les initiateurs du graffiti américain. Les premières oeuvres sur toiles des figures majeures du graffiti new-yorkais sont les rares témoins historiques physiques des prémices d’une expression populaire débridée et conquérante, devenue globale et toujours vivace. Ces oeuvres Post-graffiti réalisées par les graffeurs les plus actifs sur les murs et les métros au début des années 1980, à l’invitation des galeries new-yorkaises les plus pointues du moment (Sidney Janis, Annina Nosei, Tony Shafrazi), révèlent l’évolution de leur pratique dans l’intimité de l’atelier. Ces peintures sur toiles et papiers plus personnelles nous donnent donc l’opportunité d’entrevoir les propres fantômes des artistes, ceux qui habitent leurs oeuvres. Certains fantômes, oeuvres historiques ou contemporaines, disparaîtront et apparaîtront par surprise tout au long de l’exposition et créeront l'évènement dans l’évènement.
          </p>
          {/* <a className={DownloaderWrapper} href={DazePdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a> */}
        </div>
      </div>
      <br />
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default ThenAndNow;
